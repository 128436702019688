var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('nav',{staticClass:"flex w-full mb-6 px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-100",attrs:{"aria-label":"Breadcrumb"}},[_c('ol',{staticClass:"inline-flex items-center justify-center space-x-1 md:space-x-3 m-0"},[_c('li',{staticClass:"inline-flex items-center justify-center"},[_c('a',{staticClass:"inline-flex items-center justify-center text-sm font-medium text-gray-900",attrs:{"href":"/"}},[_c('div',{staticClass:"flex justify-center"},[_c('div',[_c('svg',{staticClass:"w-4 h-4 mr-2.5",attrs:{"aria-hidden":"true","xmlns":"http://www.w3.org/2000/svg","fill":"currentColor","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"}})])]),_c('div',[_vm._v("Inicio")])])])]),_c('li',{staticClass:"flex items-center justify-center"},[_c('div',[_c('svg',{staticClass:"w-3 h-3 text-gray-400 mx-1",attrs:{"aria-hidden":"true","xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 6 10"}},[_c('path',{attrs:{"stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"m1 9 4-4-4-4"}})])])]),_vm._m(0)])]),_c('div',{staticClass:"uk-flex uk-flex-between uk-flex-middle"},[_c('div',[(
          !_vm.isLoading &&
          _vm.userQuotables.filter(
            function (userQuotable) { return userQuotable.saved_for_later === 0; }
          ).length !== 0 &&
          _vm.storefrontStorefront.type === 'b2b'
        )?_c('button',{staticClass:"uk-button uk-button-primary uk-border-rounded",attrs:{"disabled":_vm.isLoading},on:{"click":_vm.generateQuote}},[(_vm.isLoading)?_c('span',{attrs:{"uk-spinner":""}}):_c('span',[_vm._v("Generar cotización")])]):_vm._e()])]),_c('UserQuotables',{attrs:{"userQuotables":_vm.userQuotables,"isLoading":_vm.isLoading,"editable":true}}),(
      !_vm.isLoading &&
      _vm.userQuotables.filter(
        function (userQuotable) { return userQuotable.saved_for_later === 0; }
      ).length !== 0
    )?_c('div',{staticClass:"uk-text-center uk-margin-top"},[(_vm.storefrontStorefront.type === 'b2b')?_c('button',{staticClass:"uk-button uk-button-primary uk-border-rounded",attrs:{"disabled":_vm.isLoading},on:{"click":_vm.generateQuote}},[(_vm.isLoading)?_c('span',{attrs:{"uk-spinner":""}}):_c('span',[_vm._v("Generar cotización")])]):_vm._e()]):_vm._e(),(_vm.storefrontStorefront.type === 'b2b')?_c('div',[_c('div',{staticClass:"uk-text-large uk-margin-top"},[_vm._v("Cotizaciones realizadas")]),(!_vm.isLoading)?_c('div',[(_vm.pastQuotes.length !== 0)?_c('table',{staticClass:"uk-table uk-table-divider uk-table-hover uk-table-middle uk-visible@s"},[_vm._m(1),_c('tbody',_vm._l((_vm.pastQuotes),function(pastQuote){return _c('tr',{key:pastQuote.id,style:({
              backgroundColor: _vm.isRecent(pastQuote.created_at) ? '#ffd' : '',
            })},[_c('td',{staticClass:"uk-text-center uk-text-bold uk-text-large"},[_vm._v(" #"+_vm._s(pastQuote.user_sequence)+" ")]),_c('td',[_c('div',[_vm._v(_vm._s(_vm.formatDatetime(pastQuote.created_at)))]),_c('div',[_vm._v("(Expira el "+_vm._s(_vm.formatDate(pastQuote.expires_at))+")")])]),_c('td',{staticClass:"uk-text-center"},[_vm._v(_vm._s(pastQuote.code))]),_c('td',{staticClass:"uk-text-center"},[_vm._v(" "+_vm._s(pastQuote.quotables.length)+" ")]),_c('td',{staticClass:"uk-text-center"},[(_vm.hasntExpired(pastQuote.expires_at) && pastQuote.file)?_c('div',[_c('button',{staticClass:"uk-button uk-button-primary uk-border-rounded",attrs:{"uk-icon":"mail"}}),_c('div',{attrs:{"uk-drop":"mode: click"}},[_c('div',{staticClass:"uk-card uk-padding-small uk-card-secondary uk-border-rounded"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var invalid = ref.invalid;
return [_vm._l((_vm.shareToEmails),function(email,index){return _c('ValidationProvider',{key:'email-' + index,attrs:{"name":"Correo destinatario","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{staticClass:"uk-margin-top uk-text-left"},[(_vm.shareToEmails.length !== 1)?_c('span',{staticClass:"cursor-pointer",attrs:{"uk-icon":"icon: minus-circle; ratio: 1"},on:{"click":function($event){return _vm.removeShareEmail(index)}}}):_vm._e(),_vm._v(" Correo destinatario ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.shareToEmails[index]),expression:"shareToEmails[index]"}],staticClass:"uk-input uk-border-rounded",attrs:{"type":"text","name":"Correo destinatario"},domProps:{"value":(_vm.shareToEmails[index])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.shareToEmails, index, $event.target.value)}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})}),_c('div',{staticClass:"uk-margin-small-top"},[_c('button',{staticClass:"uk-button uk-button-primary uk-border-rounded",on:{"click":_vm.addShareEmail}},[_vm._v(" Agregar correo ")])]),_c('div',{staticClass:"uk-margin-small-top"},[_c('button',{staticClass:"uk-button uk-button-primary uk-border-rounded",attrs:{"disabled":invalid || _vm.isLoading},on:{"click":function($event){return _vm.shareQuote(pastQuote)}}},[(_vm.isLoading)?_c('span',{attrs:{"uk-spinner":""}}):_c('span',[_vm._v(" Enviar ")])])])]}}],null,true)})],1)])]):_vm._e()]),_c('td',{staticClass:"uk-text-center"},[(pastQuote.file)?_c('a',{staticClass:"uk-button uk-button-primary uk-border-rounded",attrs:{"href":pastQuote.file.path,"target":"_blank"}},[_c('span',{attrs:{"uk-icon":"cloud-download"}})]):_vm._e()]),_c('td',{staticClass:"uk-text-center"},[(pastQuote.valid)?_c('router-link',{staticClass:"uk-button uk-button-primary uk-border-rounded",attrs:{"to":'/quotes/resolution/' + pastQuote.id,"uk-icon":"play"}}):_c('div',[_vm._v("Expirada")])],1)])}),0)]):_c('div',{staticClass:"uk-text-center"},[_vm._v(" No se han realizado cotizaciones ")])]):_c('div',{staticClass:"uk-text-center"},[_c('div',{attrs:{"uk-spinner":""}})])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"flex items-center justify-center"},[_c('a',{staticClass:"inline-flex items-center justify-center text-sm font-medium text-gray-900",attrs:{"href":"/quotes"}},[_vm._v(" Carrito ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('th',{attrs:{"width":"10%"}},[_vm._v("#")]),_c('th',{attrs:{"width":"20%"}},[_vm._v("Fecha de cotización")]),_c('th',{staticClass:"uk-text-center",attrs:{"width":"20%"}},[_vm._v("Código de cotización")]),_c('th',{staticClass:"uk-text-center",attrs:{"width":"10%"}},[_vm._v("Cantidad de productos")]),_c('th',{staticClass:"uk-text-center",attrs:{"width":"10%"}},[_vm._v("Compartir por correo")]),_c('th',{staticClass:"uk-text-center",attrs:{"width":"10%"}},[_vm._v("Descargar")]),_c('th',{staticClass:"uk-text-center",attrs:{"width":"20%"}},[_vm._v("Aceptar cotizazión")])])}]

export { render, staticRenderFns }